import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    sectionDisabled: Boolean,
  };

  static targets = ['section', 'hidden'];

  connect() {
    if (this.sectionDisabledValue) {
      this.disable(false);
    }
  }

  setInputState(enabledValue) {
    const formElements = this.sectionTarget.querySelectorAll('input');

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = enabledValue;
    }

    formElements.forEach((element) => {
      const inputElement = element;

      if (inputElement.type === 'checkbox') {
        inputElement.checked = enabledValue;
        inputElement.value = enabledValue;
        inputElement.disabled = !enabledValue;
      }
    });
  }

  disable() {
    this.setInputState(false);
  }

  enable() {
    this.setInputState(true);
  }
}
