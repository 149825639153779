class ExpandingRadioButton {
  constructor() {
    this.radioButtonMap = {};
    this.handleHideRadioButtonContent();
    this.initializeButtonGroup();
  }

  handleHideRadioButtonContent() {
    const expandingRadioButtons = document.querySelectorAll('.expanding_radio');
    if (expandingRadioButtons.length) {
      expandingRadioButtons.forEach((button) => {
        button.addEventListener('click', () => this.toggleButtonGroupOnClick(button.name));
        if (this.radioButtonMap[button.name]) {
          /* eslint-disable-next-line max-len */
          this.radioButtonMap = { ...this.radioButtonMap, [button.name]: [...this.radioButtonMap[button.name], button] };
        } else {
          this.radioButtonMap = { ...this.radioButtonMap, [button.name]: [button] };
        }
      });
    }
  }

  initializeButtonGroup() {
    const buttonGroups = Object.values(this.radioButtonMap);
    buttonGroups.forEach((buttonGroup) => this.toggleButtonGroup(buttonGroup));
  }

  toggleButtonGroupOnClick(buttonName) {
    this.toggleButtonGroup(this.radioButtonMap[buttonName]);
  }

  toggleButtonGroup(buttonGroup) {
    // eslint-disable-next-line consistent-return
    buttonGroup.forEach((button) => {
      this.nestedContent = button.parentNode.querySelector('.nested-content') || button.parentNode.querySelector('.expanding-content');
      if (!this.nestedContent) {
        return;
      } if (button.checked) {
        this.nestedContent.classList.remove('hidden');

        this.nestedContent.parentNode.classList.remove('gap-unset');
      } else {
        this.nestedContent.classList.add('hidden');

        this.nestedContent.parentNode.classList.add('gap-unset');
      }
    });
  }
}

window.addEventListener('turbolinks:load', () => new ExpandingRadioButton());
