function handleHideStateByCountry() {
  const country = document.querySelector('[data-country=true]');
  if (country) {
    hideOrShowState(country.value);
    country.addEventListener('change', (event) => hideOrShowState(event.target.value));
  }
}

function hideOrShowState(country) {
  const state = document.querySelector('[data-state=true]');
  if (country !== 'USA') {
    state.classList.add('hidden');
  } else {
    state.classList.remove('hidden');
  }
}

window.addEventListener('turbolinks:load', handleHideStateByCountry);
