// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import $ from 'jquery';
import HelloSign from 'hellosign-embedded';
import 'select2';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css';
import LocalTime from 'local-time';
import TurboPower from 'turbo_power';

import '../stylesheets/application';

import '@fortawesome/fontawesome-free/js/all';

global.$ = jQuery;
global.HelloSign = HelloSign;
require('controllers');
require('cleave.js');
require('bootstrap');
require('../src/add_fields');
require('../src/modal');
require('../src/agreement_creation');
require('../src/jurisdiction_selector');
require('../src/heap');
require('../src/date_time_formatter');
require('../src/remove_fields');
require('../src/agreement_reassign');
require('../src/tooltip');
require('../src/terms_of_service_modal');
// cleave.js must be imported before agreement_changed
// because we need to handle the currency mask before caching
// the values we compare in agreement_changed.js
require('../src/cleave');
require('../src/agreement_changed');
require('../src/toggle');
require('../src/checkbox_expanding_columns');
require('../src/select_hide_or_show');
require('../src/checkbox_on_input');
require('../src/send_for_signature_modal');
require('../src/attachment_selector');
require('../src/disable_checkbox');
require('../src/hide_state_by_country');
require('../src/enable_select2');
require('../src/radio_expanding');
require('../src/front_end_form_validation');
require('../src/carousel');
require('../src/input_date_placeholder');
require('../src/google_analytics');
require('../src/remove_intercom_elements_before_cache');

TurboPower.initialize(Turbo.StreamActions);
Turbo.session.drive = false;
Rails.start();
Turbolinks.start();
ActiveStorage.start();
LocalTime.start();
