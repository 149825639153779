import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    url: String,
  };

  static targets = ['placeholder'];

  connect() {
    this.poll();
  }

  poll() {
    const interval = setInterval(async () => {
      const response = await get(this.urlValue);
      if (response.ok && response.statusCode !== 202) {
        clearInterval(interval);
        this.fetchPdf();
      }
    }, 1000);
  }

  fetchPdf() {
    const object = document.createElement('object');
    object.style = 'position: sticky; top: 0; height: 100%;';
    object.setAttribute('data', this.urlValue);
    object.setAttribute('type', 'application/pdf');
    this.placeholderTarget.replaceWith(object);
  }
}
